import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs';
import { BrandState } from '../../brand/states/brand.state';
import { Program } from '../../org/models/program.model';
import { ProgramService } from '../services/program.service';
import {
  EditProgramError,
  EditProgramRequest,
  EditProgramSuccess,
  FetchProgramError,
  FetchProgramRequest,
  FetchProgramSuccess,
  SetCurrentProgramId,
} from './program.actions';

export class ProgramStateModel {
  currentProgramId: string;
  currentProgram: any;
  isLoading: boolean;
}

@State<ProgramStateModel>({
  name: 'Program',
  defaults: {
    currentProgramId: null,
    currentProgram: null,
    isLoading: false,
  },
})
@Injectable()
export class ProgramState {
  constructor(
    private readonly store: Store,
    private readonly programService: ProgramService,
  ) {}

  @Selector()
  static programId(state: ProgramStateModel): string {
    return state.currentProgramId;
  }

  @Selector()
  static currentProgram(state: ProgramStateModel): Program {
    return state.currentProgram;
  }

  @Action(SetCurrentProgramId)
  setCurrentProgramId(
    ctx: StateContext<ProgramStateModel>,
    { programId }: SetCurrentProgramId,
  ) {
    ctx.patchState({
      currentProgramId: programId,
    });
    ctx.dispatch(new FetchProgramRequest(programId));
  }
  
  @Action(FetchProgramRequest)
  fetchProgramRequest(
    ctx: StateContext<ProgramStateModel>,
    { programId }: FetchProgramRequest,
  ) {
    ctx.patchState({ isLoading: true });

    return this.programService
      .getProgram(this.store.selectSnapshot(BrandState.brandId), programId)
      .pipe(
        tap((program: Program) => {
          ctx.dispatch(new FetchProgramSuccess(program));
        }),
        catchError(error => ctx.dispatch(new FetchProgramError(error))),
      );
  }

  @Action(FetchProgramSuccess)
  fetchProgramSuccess(
    ctx: StateContext<ProgramStateModel>,
    { program }: FetchProgramSuccess,
  ) {
    ctx.patchState({
      currentProgram: program,
      isLoading: false,
    });
  }

  @Action(FetchProgramError)
  fetchProgramError(ctx: StateContext<ProgramStateModel>, { error }: FetchProgramError) {
    ctx.patchState({ isLoading: false });
  }

  @Action(EditProgramRequest)
  editProgramRequest(
    ctx: StateContext<ProgramStateModel>,
    { programData }: EditProgramRequest,
  ) {
    ctx.patchState({ isLoading: true });
    const brandId = this.store.selectSnapshot(BrandState.brandId);
    const programId = ctx.getState().currentProgramId;

    return this.programService.editProgram(brandId, programId, programData).pipe(
      tap((updateProgram: Program) => {
        ctx.dispatch(new EditProgramSuccess(updateProgram));
      }),
      catchError(error => ctx.dispatch(new EditProgramError(error))),
    );
  }

  @Action(EditProgramSuccess)
  editProgramSuccess(
    ctx: StateContext<ProgramStateModel>,
    { program }: EditProgramSuccess,
  ) {
    ctx.patchState({
      currentProgram: program,
      isLoading: false,
    });
  }

  @Action(EditProgramError)
  editProgramError(ctx: StateContext<ProgramStateModel>, { error }: EditProgramError) {
    ctx.patchState({ isLoading: false });
    console.error('Edit Program Error:', error);
  }
}
