import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs';
import { BrandState } from '../../brand/states/brand.state';
import { ProgramInput } from '../models/input.model';
import { ProgramService } from '../services/program.service';
import {
  FetchProgramInputsRequest,
  FetchProgramInputsSuccess,
  FetchProgramInputsError,
} from './inputs.actions';
import { ProgramState } from './program.state';

export class InputStateModel {
  inputs: ProgramInput[];
  isLoading: boolean;
}

@State<InputStateModel>({
  name: 'Input',
  defaults: {
    inputs: null,
    isLoading: false,
  },
})
@Injectable()
export class InputState {
  constructor(
    private readonly store: Store,
    private readonly programService: ProgramService,
  ) {}

  @Selector()
  static programInputs(state: InputStateModel): ProgramInput[] {
    return state.inputs;
  }

  @Action(FetchProgramInputsRequest)
  fetchProgramInputsRequest(ctx: StateContext<InputStateModel>) {
    ctx.patchState({ isLoading: true });

    const brandId = this.store.selectSnapshot(BrandState.brandId);
    const programId = this.store.selectSnapshot(ProgramState.programId);

    return this.programService.getInputs(brandId, programId).pipe(
      tap((inputs: any) => {
        ctx.dispatch(new FetchProgramInputsSuccess(inputs));
      }),
      catchError(error => ctx.dispatch(new FetchProgramInputsError(error))),
    );
  }

  @Action(FetchProgramInputsSuccess)
  fetchProgramInputsSuccess(
    ctx: StateContext<InputStateModel>,
    { inputs }: FetchProgramInputsSuccess,
  ) {
    ctx.patchState({
      inputs,
      isLoading: false,
    });
  }

  @Action(FetchProgramInputsError)
  fetchProgramInputsError(
    ctx: StateContext<InputStateModel>,
    { error }: FetchProgramInputsError,
  ) {
    ctx.patchState({ isLoading: false });
    console.error('Fetch Program Inputs Error:', error);
  }
}
