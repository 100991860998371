export interface ProgramInputApiItem {
    backup_file_source_id: string;
    created_at: string;
    id: string;
    name: string;
    primary_source_id: string;
    program_id: string;
    secondary_source_id: string;
    updated_at: string;
}

export interface ProgramInput {
    backupFileSourceId: string;
    createdAt: string;
    id: string;
    name: string;
    primarySourceId: string;
    programId: string;
    secondarySourceId: string;
    updatedAt: string;
}

export function convertToInput(apiItem: ProgramInputApiItem): ProgramInput {
    return {
        backupFileSourceId: apiItem.backup_file_source_id,
        createdAt: apiItem.created_at,
        id: apiItem.id,
        name: apiItem.name,
        primarySourceId: apiItem.primary_source_id,
        programId: apiItem.program_id,
        secondarySourceId: apiItem.secondary_source_id,
        updatedAt: apiItem.updated_at,
    };
}

export function convertToInputApiItem(input: ProgramInput): ProgramInputApiItem {
    return {
        backup_file_source_id: input.backupFileSourceId,
        created_at: input.createdAt,
        id: input.id,
        name: input.name,
        primary_source_id: input.primarySourceId,
        program_id: input.programId,
        secondary_source_id: input.secondarySourceId,
        updated_at: input.updatedAt,
    };
}