import { Program } from '../../org/models/program.model';

const tag = '[Program]';

export class SetCurrentProgramId {
  static readonly type = `${tag} set current program id`;
  constructor(public programId: string) {}
}

export class FetchProgramRequest {
  static readonly type = `${tag} fetch program request`;
  constructor(public programId: string) {}
}

export class FetchProgramSuccess {
  static readonly type = `${tag} fetch program success`;
  constructor(public program: Program) {}
}

export class FetchProgramError {
  static readonly type = `${tag} fetch program error`;
  constructor(public error: any) {}
}

export class EditProgramRequest {
  static readonly type = `${tag} edit program request`;
  constructor(public programData: Partial<Program>) {}
}

export class EditProgramSuccess {
  static readonly type = `${tag} edit program success`;
  constructor(public program: Program) {}
}

export class EditProgramError {
  static readonly type = `${tag} edit program error`;
  constructor(public error: any) {}
}