import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  convertToApiProgramItem,
  convertToProgram,
  Program,
  ProgramApiItem,
} from '../../org/models/program.model';
import { convertToInput, ProgramInput, ProgramInputApiItem } from '../models/input.model';

const PROGRAM_URLS = {
  program: (brandId: string, id: string) =>
    `${environment.urls.RK_BUSINESS_API}/brands/${brandId}/programs/${id}`,
  programInputs: (brandId: string, id: string) =>
    `${environment.urls.RK_BUSINESS_API}/brands/${brandId}/programs/${id}/inputs`,
};

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  constructor(private readonly http: HttpClient) {}

  getProgram(brandId: string, id: string): Observable<Program> {
    return this.http
      .get<ProgramApiItem>(PROGRAM_URLS.program(brandId, id))
      .pipe(map((apiItem: ProgramApiItem) => convertToProgram(apiItem)));
  }

  editProgram(
    brandId: string,
    id: string,
    program: Partial<Program>,
  ): Observable<Program> {
    return this.http
      .put<ProgramApiItem>(PROGRAM_URLS.program(brandId, id), convertToApiProgramItem(program))
      .pipe(map((apiItem: ProgramApiItem) => convertToProgram(apiItem)));
  }

  getInputs(brandId: string, id: string): Observable<ProgramInput[]> {
    return this.http
      .get<ProgramInputApiItem[]>(PROGRAM_URLS.programInputs(brandId, id))
      .pipe(map((apiItems: ProgramInputApiItem[]) => apiItems.map(convertToInput)));
  }
}
