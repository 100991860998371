import { ProgramInput } from '../models/input.model';

const tag = '[Inputs]';

export class FetchProgramInputsRequest {
    static readonly type = `${tag} fetch program inputs request`;
    constructor() {}
}

export class FetchProgramInputsSuccess {
    static readonly type = `${tag} fetch program inputs success`;
    constructor(public inputs: ProgramInput[]) {}
}

export class FetchProgramInputsError {
    static readonly type = `${tag} fetch program inputs error`;
    constructor(public error: any) {}
}